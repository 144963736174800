<template>
  <div class="container">
    <section class="orders-header">
      <h1 class="titlePage titlePage_textLeft"> {{getUserName}} </h1>
    </section>

    <section>
      <div class="row">
        <div class="col-sm-6 col-12">
          <label class="form__label">
            <input type="text" class="form__input"
                   name="name"
                   :placeholder="isSetState ? 'Type Set Name' : 'Type Package Name'"
                   v-model="packageName"
            >
          </label>
        </div>
      </div>

      <p v-if="optionsList.length < 0 || options.length < 0"
         class="text text_green text_italic">
        No options available to configure</p>

      <scopped-options v-else
                       :options="options"
                       :type="type"
                       :set="isSet"
                       :editFromSet="IsEditFromSet"
                       :isEdit="IsEdit"
      />
    </section>

    <div class="row" v-if="allOptions">
      <div class="form__row form__row_full form__row_margin form__row_start ml-2">
        <button class="btn_default btn" type="submit" @click="updateSets" v-if="IsEdit">save changes</button>
        <button class="btn_default btn" type="submit" @click="actionSet" v-else>create package for set</button>
      </div>
    </div>
  </div>
</template>

<script>
  import router from "../../router";
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import {defineAsyncComponent} from "vue";
  const warningOutOfSquare =  defineAsyncComponent(() => import('../modals/WarningOutOfSquare'));
  const breadcrumbs =  defineAsyncComponent(() => import("./../parts/breadcrumbs"));
  const ScoppedOptions =  defineAsyncComponent(() => import("./options/ScoppedOptions"));

  export default {
    name: "SelectOptionsToSet",

    components: {
      breadcrumbs,
      warningOutOfSquare,
      ScoppedOptions,
    },

    props: {
      isSet: Boolean,
      appendPackage: Boolean,
      checkBox: Boolean,
      package: Boolean,
    },

    data() {
      return {
        doneOptions: ['MESH', 'INS', 'INSCOLOUR', 'LOCK', 'HOPH', 'CYL', 'WHL', 'HNG', 'LFTT', 'MFLP', 'PRTS'],
        packageName: '',
        selectedOptions: {},
        prevOption: ''
      }
    },

    methods: {
      ...mapMutations('options', ['setOptionsToOrder', 'setOptionsToPackage', 'setShowAll']),

      actionSet() {
        if (!this.packageName) {
          return this.$store.commit('errorsModal/openModal', {0: ['You must enter name']});
        }

        if (!this.appendPackage) {
          return this.createSet();
        }
        this.$store.commit('sets/set', {k: 'packageName', v: this.packageName});
        this.$store.dispatch('sets/appendPackageToSet');
        this.$store.commit('createdOrder/selectModal', false);
        router.push({name: 'sets'});
      },

      createSet() {
        this.$store.commit('sets/set', {k: 'packageName', v: this.packageName});
        this.$store.dispatch('sets/createSetWithPackage');
        this.$store.commit('createdOrder/selectModal', false);
        router.push({name: 'sets'});
      },

      updateSets() {
        this.$store.dispatch('sets/updatePackageInSet', this.packageName);
      },

      checkAvailableOptions() {
        if (!this.allOptions) {
          this.setOptionsToPackage(this.options);
          this.setShowAll(true);
          return;
        }
      },

      checkPackageName() {
        if (this.oldPackageName !== '') {
          if (typeof this.oldPackageName === "function") {
            this.packageName = this.oldPackageName();
          } else {
            this.packageName = this.oldPackageName;
          }

        }
        else{
          return this.packageName = '';
        }
      }
    },

    computed: {
      isSetState() {
        return this.$store.getters['sets/get']('isSetState');
      },

      oldPackageName() {
        return this.$store.getters['sets/get']('packageName');
      },

      getUserName() {
        return localStorage.getItem('nameUser');
      },

      getSelectedOptions() {
        return this.selectedOptions;
      },

      ...mapGetters({
        IsEdit: 'packages/IsEdit',
        IsEditFromSet: 'packages/IsEditFromSet',
        IsEditFromPackage: 'packages/IsEditFromPackage',
        user: 'auth/nameUser',
        options: 'options/options',
        optionsList: 'options/optionsList',
        type: 'options/type',
        orderItem: 'createdOrder/createdOrder',
        update: 'types/updates',
      }),

      ...mapState('options', ['allOptions', 'itemId', 'incomplete']),

      disabledFromIncomplete() {
        if (this.incomplete.length > 0) {
          return true;
        }
        else return false;
      },
    },

    created() {
      this.$store.dispatch('options/getOptionsToSet');
      this.checkPackageName();
    },

    beforeUpdate() {
      this.checkAvailableOptions();
    },

    beforeDestroy(){
      this.$store.commit('options/cleanOptions');
    }
  }
</script>

<style lang="scss" scoped>

  @import '../../assets/scss/utils/vars';

  .option_name {
    text-transform: capitalize;
    text-align: center;
  }

  .form__input {
    border: none;
    border-bottom: 2px solid $navy;
  }
</style>
